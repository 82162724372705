import { matchPath } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { initializeClient } from 'store/app/app.slice';
import { routeWithoutInit } from './routes';
import App from './App';
import { getClientPrefixFromUrl } from './clientPrefix';

export const AppWrapper = () => {
    const currentPath = window.location.pathname;
    const pathWithoutInit = routeWithoutInit.find((route) => matchPath({
        path: route.path,
        exact: false,
    }, currentPath));

    const dispatch = useDispatch();

    if (!pathWithoutInit) {
        return <App />;
    }

    const clientPrefix = getClientPrefixFromUrl();
    initializeClient([clientPrefix], clientPrefix, dispatch);

    return pathWithoutInit.component;
};
