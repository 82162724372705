export const UserOrganisationType = {
    Supplier: 0,
    Internal: 1,
    Customer: 2,
};

export const viewTypes = {
    INTRODUCTION: '0',
    TABLEOFCONTENTS: '1',
    QUESTIONSECTION: '2',
    VERIFY: '3',
    EXPIRED: '4',
    INVALID: '5',
    NOACCESS: '6',
    CANCELLED: '7',
};

export const McatMenuSectionTypes = {
    Search: 0,
    RecentlySelected: 1,
    Action: 2,
    LinkGroup: 3,
    KeyAssessment: 4,
    Search_WithName: 5,
};

export const CompletenessStatus = {
    Finished: 0,
    NotFinished: 1,
    NotRequired: 2,
    InProgress: 3,
};

export const WYSIWYGToolsContext = {
    NoContext: 'NoContext',
    Comment: 'Comment',
    Assessments: 'Assessments',
    InfoPages: 'InfoPages',
    ProfessionalSummary: 'ProfessionalSummary',
    Dashboards: 'Dashboards',
    ExtendedMenu: 'ExtendedMenu',
    TermsAndConditions: 'TermsAndConditions',
};

export const DescriptionRelationType = {
    AdditionalCategory: 0,
    RootCause: 1,
    ProblemClassification: 2,
    FailureEffect: 3,
    RolloutStep: 4,
    NCRContext: 5,
};

export const MeercatContextCodes = {
    Supplier: 0,
    Tasks: 1,
    NCR: 2,
    Project: 3,
    Resource: 4,
    Cost: 5,
    Tool: 6,
    Customer: 7,
    PPAP: 8,
    PPAP_PART: 9,
    FilterBy: 10,
    GroupBy: 11,
    Comment: 12,
    Category: 13,
    TargetConfig: 14,
    Contract: 15,
    Catalogue: 16,
    RfXEvent: 17,
};

export const HistoryContext = {
    Unknown: 0,
    Part: 1,
};
