import { IconWrapper, SvgIconWrapper } from './icon.styles';

export const Icon = (props) => {
    const {
        fontSize, color, xs, sm, md, lg, xl, xxl, icon, onClick, clickable, style,
} = props;
    return <IconWrapper
        data-test-id={props['data-test-id']}
        onClick={onClick}
        title={props.title}
        className={`${props.className} ${clickable ? 'clickable' : ''}`}
        fontSize={fontSize}
        style={style}
        color={color}
        xs={xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
        xxl={xxl}>
            <i className={`${icon}`}></i>
    </IconWrapper>;
};

export const SvgIcon = (props) => <SvgIconWrapper {...props} dangerouslySetInnerHTML={{ __html: props.icon }} />;
