import { ProtectedRoute } from 'components/auth/protected-route.component';
import { lazy } from 'react';
import { LazyWrapper } from '../routes';

const ContractsReportsLazy = lazy(() => import('routes/contracts/reports/contractsReports.component'));

export const contractsRoutes = [
    // contracts
    {
        path: ':clientPrefix/contracts/reports/index',
        component: <LazyWrapper><ProtectedRoute><ContractsReportsLazy/></ProtectedRoute></LazyWrapper>,
    },
];
