import { useEffect } from 'react';

export const useTabIndexCleaner = (classNames) => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'tabindex') {
            const { target } = mutation;

            classNames.forEach((s) => {
              if (target.classList.contains(s)) {
                target.removeAttribute('tabindex');
              }
            });
        }

        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === 1) {
              if (node.hasAttribute('tabindex')) {
                classNames.forEach((s) => {
                  if (node.classList.contains(s)) {
                    node.removeAttribute('tabindex');
                  }
                });
              }

              const tabbableChildren = node.querySelectorAll('[tabindex]');
              tabbableChildren.forEach((child) => {
                classNames.forEach((s) => {
                  if (child.classList.contains(s)) {
                    child.removeAttribute('tabindex');
                  }
                });
              });
            }
          });
        }
      });
    });

    observer.observe(document.body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [classNames]);
};
