import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import NotificationBar from 'components/notification-bar/notification-bar.component';
import NotificationBarSmall from 'components/notification-bar-small/notification-bar-small.component';
import { GlobalStyle } from 'app/app.styles';
import { Spinner } from 'components/spinner/spinner.component';
import { MOTAPIInterceptorInitializer } from 'utils/motApi';
import { SignIn } from 'components/auth/signin/signIn.component';
import { SignOut } from 'components/auth/signout/signOut.component';
import { ScrollTop } from 'components/scroll-top/scroll-top.component';
import { AppWrapper } from 'app/AppWrapper';
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
      <BrowserRouter>
          <>
            <MOTAPIInterceptorInitializer/>
            <GlobalStyle/>
            <Spinner/>
            <NotificationBar/>
            <NotificationBarSmall/>
            <SignIn />
            <SignOut />
            <ScrollTop />
            <AppWrapper />
          </>
      </BrowserRouter>
    </Provider>,
);
